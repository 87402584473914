import './Header.scss'
import { Link } from 'react-router-dom'
import logo from "../../assets/logo.svg"
import React, { useContext } from 'react'
import { ProjectsContext } from '../../utils/ProjectsContext'
import Loader from '../Loader/Loader'
import AuthModal from '../AuthModal/AuthModal'

 
function Header() {
    
    const { loaderDisplay, openAuthModal, displayAuthModal, closeAuthModal } = useContext(ProjectsContext);

    return  (      
        <header className='header--fixed'>
            {loaderDisplay===true &&
                <Loader/>
            }
            <div className='header_topBar'>
                <Link className='header_topBar_link' aria-label="Accéder à la page d'accueil" to="/">
                    <p>+ shells in my pocket +</p>
                </Link>
                <button onClick={()=>openAuthModal()} className='header_topBar_rightButton'>
                    <img src={logo} alt="Logo" className="header_topBar_rightButton_logo" />
                </button>
            </div>
            {displayAuthModal===true &&
                <AuthModal/>
            }
            {/* <NavSection displayNavSection={displayNavSection}/> */}
        </header>
    )
}

export default Header