import './NavSection.scss'
import { Link } from 'react-router-dom'
import React, { useContext} from 'react'
import { ProjectsContext } from '../../utils/ProjectsContext'

 
function NavSection() {

    const { isAuthenticated, setLoggedOut } = useContext(ProjectsContext);

    return  (      
        <div className='navSection navSection--display'>
            <nav className='navSection_navContainer'>
                <ul className='navSection_navContainer_navGrid'>
                    <li className='navSection_navContainer_navGrid_item'>
                        <div className='navSection_navContainer_navGrid_item_content'>
                            {isAuthenticated===true &&
                            <div>
                                <h2>ADMIN</h2>
                                <ul>
                                    <li>
                                        <button onClick={()=>setLoggedOut()}aria-label="Log out" to="/"><p>Log out</p></button>
                                    </li>
                                    <li>
                                        <Link aria-label="Accéder à la page d\'édition" to="/edit"><p>Edit</p></Link>
                                    </li>
                                    
                                </ul>
                            </div>
                            }
                        </div>
                    </li>
                    <li className='navSection_navContainer_navGrid_item'>
                        {/* <p className='navSection_navContainer_navGrid_item_index'>02</p> */}
                        {/* <div className='navSection_navContainer_navGrid_item_content'>
                            <h2>RÉSEAUX</h2>
                            <ul>
                                <li>
                                    <a href='http://mixcloud.com/benjivolt/' target='_blank' rel='noreferrer'> Mixcloud </a>
                                </li>
                                <li>
                                    <a href='http://soundcloud.com/benjamin-gibert' target='_blank' rel='noreferrer'> Soundcloud </a>
                                </li>
                                <li>
                                    <a href='http://instagram.com/benngvolt' target='_blank' rel='noreferrer'> Instagram </a>
                                </li>
                            </ul>  
                        </div> */}
                    </li>
                </ul>
            </nav>
            <div className='navSection_imageContainer navSection_imageContainer--opened'>
                
            </div>
        </div>
    )
}

export default NavSection