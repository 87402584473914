import './FormCaptionField.scss'
import React, { forwardRef, useEffect } from 'react'

// import { ProjectsContext } from '../../utils/ProjectsContext'

const FormCaptionField = forwardRef(({htmlFor, label, type, id, value, onChangeFunction, projectFormMode, onChangeDateFunction, index, closeModal, setDateCaption, dateCaption, captionSubmit, imageFiles}, ref) => {
    
    let today = new Date();
    let day = String(today.getDate()).padStart(2, '0'); // Ajoute un zéro pour les jours inférieurs à 10
    let month = String(today.getMonth() + 1).padStart(2, '0'); // Mois entre 0 et 11, donc on ajoute 1
    let year = today.getFullYear();
    let currentDate = year + '-' + month + '-' + day;

    // Formater la date au format YYYY-MM-DD
    useEffect(() => {
        if (projectFormMode==='add') {
            setDateCaption(currentDate)
        } else if (projectFormMode==='edit') {
            if (!dateCaption || dateCaption==='') {
                setDateCaption(currentDate)
            }
        }
    }, []);
    


    return (
        <div className='formCaptionField'>
            <img
                src={imageFiles[index].imageUrl}
                alt={`image ${index}`}/>
            {/* <label htmlFor={htmlFor}>{label}</label> */}
            <textarea type={type} id={id} ref={ref} value={value} onChange={(e) => onChangeFunction(index, e.target.value)}></textarea>
            <input type="date" id="date" name="date" defaultValue={dateCaption} onChange={(e) => onChangeDateFunction(index, e.target.value)}/>
            <button type='button' onClick={() => captionSubmit(index, value, dateCaption)}>VALIDER</button>
            <button type='button' onClick={() => closeModal()}>FERMER</button>
        </div>
    )
})

export default FormCaptionField