import './SingleProject.scss'
// import React, { useContext } from 'react'
import { ProjectsContext } from '../../utils/ProjectsContext'
import { useParams } from 'react-router-dom'
import CaptionBox from '../../components/CaptionBox/CaptionBox'
import React, { useContext, useState, useEffect } from 'react'
import { API_URL } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft , faChevronRight} from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify';

function SingleProject () {

    const { displayNavSection, setDisplayNavSection } = useContext(ProjectsContext);
    const [singleProject, setSingleProject] = useState(null);
    const [selectedSketchIndex, setSelectedSketchIndex] = useState(0);
    const [handleDisplayCaptionBox, setHandleDisplayCaptionBox] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        setDisplayNavSection(false)
    }, []);

    useEffect(() => {
        fetch(`${API_URL}/api/projects/${id}`)
            .then((res) => res.json())
            .then((data) => {
                // setDisplayHeader(true);
                window.scrollTo(0, 0);
                setSingleProject(data);
            })
            .catch((error) => console.log(error.message));
    }, [id]);

    const cleanedDescription = DOMPurify.sanitize(singleProject?.description);

    function openCaptionBox(index){
        setHandleDisplayCaptionBox(true);
        setSelectedSketchIndex(index);
    }



    return (
        <main>
            {singleProject &&
            <div className={`singleProject_${singleProject.projectType}`}>
                <section className={`singleProject_datasContainer singleProject_datasContainer_${singleProject.projectType}`}> 
                    <article className='singleProject_datasContainer_datasBox'>
                        <h3 className='singleProject_datasContainer_datasBox_title'>{singleProject.title}</h3>
                        <p className='singleProject_datasContainer_datasBox_description' dangerouslySetInnerHTML={{__html:cleanedDescription}}></p>
                    </article>
                    
                    <div className='singleProject_datasContainer_galleries'>
                        <div className='singleProject_datasContainer_galleries_sketches'>  
                            {singleProject.sketches.length > 0 &&
                            <div className='singleProject_datasContainer_galleries_sketches_grid'> 
                                {singleProject.sketches.map((sketch, index)=>(
                                    <div className={`singleProject_datasContainer_galleries_sketches_grid_image singleProject_datasContainer_galleries_sketches_grid_image_${index}`}
                                    onClick={() => {
                                        openCaptionBox(index);
                                        // D'autres instructions si nécessaire
                                    }}>{sketch.sketchDate &&
                                        <p className='singleProject_datasContainer_galleries_sketches_grid_index'>{sketch.sketchDate.split('-')[2]}.{sketch.sketchDate.split('-')[1]}.{sketch.sketchDate.split('-')[0].slice(-2)}</p>
                                    }
                                        <img className={sketch.imageUrl.endsWith('.png')?'singleProject_datasContainer_galleries_sketches_grid_image_png' : 'singleProject_datasContainer_galleries_sketches_grid_image_other'} src={sketch.imageUrl}/> 
                                    </div>
                                ))}
                            </div>
                            }
                            {handleDisplayCaptionBox===true &&
                                <CaptionBox sketches={singleProject.sketches} index={selectedSketchIndex} setHandleDisplayCaptionBox={setHandleDisplayCaptionBox}/>
                            }
                        </div>
                        {/* <div className='singleProject_datasContainer_galleries_imagesWowGrid'>
                            {singleProject.images.length > 1 &&
                                <div className='singleProject_datasContainer_galleries_imagesWowGrid_grid'> 
                                    {singleProject.images.map((image, index)=>(
                                        <img className='singleProject_datasContainer_galleries_imagesWowGrid_grid_image' src={image.imageUrl}/>
                                    ))}
                                </div>
                            }   
                        </div> */}
                    </div>
                </section>
                
            </div>
            }
        </main>
    )
}

export default SingleProject