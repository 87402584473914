import './ProjectForm.scss'
import FormSimpleField from '../FormSimpleField/FormSimpleField'
import FormCaptionField from '../FormCaptionField/FormCaptionField'
import Loader from '../Loader/Loader'
import React, { useState, useRef, useEffect} from 'react'
import FormSelectionField from '../FormSelectionField/FormSelectionField'
import FormRichTextField from '../FormRichTextField/FormRichTextField'
import FormImageField from '../FormImageField/FormImageField'
import DNDGallery from '../DNDGallery/DNDGallery'
import { API_URL } from '../../utils/constants'


function ProjectForm ({
        handleLoadProjects, 
        projectFormMode, 
        projectEdit, 
        setProjectEdit, 
        setDisplayProjectForm, 
        loaderDisplay, 
        setLoaderDisplay
    }) {

    
    const [projectTitle, setProjectTitle] = useState('')
    const [projectSubtitle, setProjectSubtitle] = useState('')
    const [projectType, setProjectType] = useState('OLIVE')
    const [projectState, setProjectState] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [imageFiles, setImageFiles] = useState ([])
    const [sketchFiles, setSketchFiles] = useState ([])
    const [caption, setCaption] = useState ('')
    const [dateCaption, setDateCaption] = useState ('')
    const [mainImageIndex, setMainImageIndex] = useState(0)
    const [mainSketchIndex, setMainSketchIndex] = useState(0)
    const [captionIndex, setCaptionIndex] = useState(null)
    const [captionModalDisplay, setCaptionModalDisplay] = useState(false)

    const inputProjectTitleRef = useRef('');
    const inputProjectSubtitleRef = useRef(null);
    const inputProjectTypeRef = useRef(null);
    const inputProjectStateRef = useRef(null);
    const inputProjectDateRef = useRef(null);
    const inputProjectDescriptionRef = useRef(null)
    const inputProjectPriceRef = useRef(null)
    const inputProjectSurfaceRef = useRef(null)
    const inputImageCaptionRef = useRef(null);

    const projectTypes = ['OLIVE', 'SAND', 'SLATE', 'PINK']
    const projectStates = ['à venir', 'en cours', 'terminé']


    useEffect(() => {
        formatFields()
    }, [projectFormMode]);

    function formatFields() {
        
        if (projectFormMode === 'add') {
            setProjectTitle('');
            setProjectSubtitle('');
            setProjectType('OLIVE');
            setProjectState('');
            setProjectDescription('');
            setMainImageIndex(0);
            setImageFiles([]);
            setSketchFiles([])
        } else {
            setProjectTitle(projectEdit.title);
            setProjectSubtitle(projectEdit.subtitle);
            setProjectType(projectEdit.projectType || 'OLIVE');
            setProjectState(projectEdit.projectState);
            setProjectDescription(projectEdit.description);
            setMainImageIndex(projectEdit.mainImageIndex ?? 0);
            setImageFiles(projectEdit.images ?? []);
            setSketchFiles(projectEdit.sketches ?? []);
        }
    }



    useEffect(() => {
        const element = document.getElementById("inputProjectDescription");
        if (element) {
            element.editor.setSelectedRange([0, 0]);
            element.editor.loadHTML(projectDescription); 
        }
    }, [projectDescription, projectFormMode]);

    function handleCaptionChange(index, newCaption) {

        setCaption(newCaption)
        // const updatedCaptions = [...sketchCaptions];
        // updatedCaptions[index] = newCaption;
        // setSketchCaptions(updatedCaptions);
    }
    function handleDateChange(index, newDate) {

        setDateCaption(newDate)
        // const updatedCaptions = [...sketchCaptions];
        // updatedCaptions[index] = newCaption;
        // setSketchCaptions(updatedCaptions);
    }

    function openCaptionModal(index) {
        setCaptionIndex(index);
        setCaption(sketchFiles[index].sketchCaption);
        setDateCaption(sketchFiles[index].sketchDate);
        setCaptionModalDisplay(true);
    }

    function closeCaptionModal() {
        setCaptionIndex(null);
        setCaption('');
        setDateCaption('');
        setCaptionModalDisplay(false);
    }

    function captionSubmit(index, value, dateCaption) {
        const updatedSketches = [...sketchFiles];
        updatedSketches[index].sketchCaption = value;
        updatedSketches[index].sketchDate = dateCaption;
        setSketchFiles(updatedSketches);
        closeCaptionModal()
    }

    /* --------------------------------------
    ----- SOUMISSION DU FORMULAIRE ----------
    ---------------------------------------*/

    function projectFormSubmit(event) {
        event.preventDefault();
        setLoaderDisplay(true);
        // const token = window.sessionStorage.getItem('1');
        const projectFormData = new FormData();
        projectFormData.append('title', inputProjectTitleRef.current.value);
        projectFormData.append('subtitle', inputProjectSubtitleRef.current.value);
        projectFormData.append('projectType', inputProjectTypeRef.current.value);
        projectFormData.append('projectState', inputProjectStateRef.current.value);
        projectFormData.append('description', inputProjectDescriptionRef.current.value);
        projectFormData.append('mainImageIndex', mainImageIndex);

        const newImageFiles = Array.from(imageFiles);
        const newSketchFiles = Array.from(sketchFiles);
        
        const imagesWithIndex = newImageFiles.map((image, index) => ({
            index,
            image
        }));
        
        const sketchesWithIndex = newSketchFiles.map((sketch, index) => ({
            index,
            sketch
        }));

        imagesWithIndex.forEach(({ index, image }) => {
            if (image instanceof File) {
                projectFormData.append('images', image);
                projectFormData.append('fileIndexes', index)
            } else {
                projectFormData.append(`existingImages[${index}]`, JSON.stringify(image));
            }
        });

        sketchesWithIndex.forEach(({ index, sketch }) => {
            if (sketch instanceof File) {
                projectFormData.append('sketches', sketch);
                projectFormData.append('sketchFileIndexes', index)
            } else {
                projectFormData.append(`existingSketches[${index}]`, JSON.stringify(sketch));
            }
        });

        if (
            !inputProjectTitleRef.current.value ||
            !inputProjectTypeRef.current.value
        ) {
            setLoaderDisplay(false);
            // setDisplayError(true);
            return;
        }
        else {
            if (projectFormMode==='add') {
                fetch(`${API_URL}/api/projects`, {
                    method: "POST",
                    headers: {
                        //'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer ' + token,
                        Authorization: `Bearer ${sessionStorage.getItem('1')}`
                    },
                    body: projectFormData,
                    })
                    .then((response) => {
                        if (response.ok) {
                            return response;
                        } else {
                            // setDisplayServerError(true);
                            throw new Error('La requête a échoué');
                        }
                    })
                    .then(()=> {
                        handleLoadProjects();
                        setDisplayProjectForm(false)
                        setLoaderDisplay(false);
                        // openValidBox();
                    })
                    .catch((error) => {
                        console.error(error);
                        // setDisplayServerError(true);
                });
            } else if (projectFormMode==='edit') {
                console.log('edit')
                fetch(`${API_URL}/api/projects/${projectEdit._id}`, {
                    method: "PUT",
                    headers: {
                        // 'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer ' + token,
                        Authorization: `Bearer ${sessionStorage.getItem('1')}`
                    },
                    body: projectFormData,
                    })
                    .then((response) => {
                        if (response.ok) {
                            return response;
                        } else {
                            // setDisplayServerError(true);
                            throw new Error('La requête a échoué');
                        }
                    })
                    .then(()=> {
                        handleLoadProjects();
                        setDisplayProjectForm(false)
                        setLoaderDisplay(false);
                        // openValidBox();
                    })
                    .catch((error) => {
                        console.error(error);
                        // setDisplayServerError(true);
                        setLoaderDisplay(false);
                });
            }
        }
    }

    return (
        <div className='projectFormContainer'>
            <form className={`projectForm projectForm_${projectType}`} onSubmit={(event) => projectFormSubmit(event)} method="post">
                {loaderDisplay===true &&
                    <Loader/>
                }
                <div className='projectForm_closeButton'>
                    <button type='button' onClick={() => setDisplayProjectForm(false)}>X FERMER</button>
                </div>
                <div className='projectForm_form'>
                    <FormSimpleField
                        htmlFor={'inputProjectTitle'}
                        label={'TITRE*'}
                        type={'text'}
                        id={'inputProjectTitle'}
                        ref={inputProjectTitleRef}
                        value={projectTitle}
                        onChangeFunction={setProjectTitle}
                    /> 
                    <FormSimpleField
                        htmlFor={'inputProjectSubtitle'}
                        label={'SOUS-TITRE*'}
                        type={'text'}
                        id={'inputProjectSubtitle'}
                        ref={inputProjectSubtitleRef}
                        value={projectSubtitle}
                        onChangeFunction={setProjectSubtitle}
                    />
                    <FormSelectionField
                        htmlFor={'inputProjectState'}
                        label={'ÉTAT DU VOYAGE*'}
                        type={'text'}
                        id={'inputProjectState'}
                        ref={inputProjectStateRef}
                        value={projectState}
                        onChangeFunction={setProjectState}
                        selectionArray={projectStates}
                    />
                    <FormSelectionField
                        htmlFor={'inputProjectType'}
                        label={'COULEUR DU FOND*'}
                        type={'text'}
                        id={'inputProjectType'}
                        ref={inputProjectTypeRef}
                        value={projectType}
                        onChangeFunction={setProjectType}
                        selectionArray={projectTypes}
                    />
                    <FormRichTextField
                        htmlFor={'inputProjectDescription'}
                        label={'DESCRIPTION DU VOYAGE'}
                        type={'hidden'}
                        id={'inputProjectDescription'}
                        ref={inputProjectDescriptionRef}
                        name={'projectDescription'}
                        value={projectDescription}
                    />
                    <div>
                        <p className='projectForm_form_title'><strong>GALLERIE D'OBJETS</strong></p>
                    </div>
                    <DNDGallery
                        isCaptionFormAvailable={true}
                        imageFiles={sketchFiles} 
                        setImageFiles={setSketchFiles} 
                        mainImageIndex={mainSketchIndex} 
                        setMainImageIndex={setMainSketchIndex} 
                        displayClass={'grid'}
                        openCaptionModal={openCaptionModal}/>
                    <FormImageField
                        htmlFor={'inputSketch'}
                        label={'TÉLÉCHARGER UN CROQUIS'}
                        type={'file'}
                        id={'inputSketch'}
                        name={'sketch'}
                        imageFiles={sketchFiles}
                        setImageFiles={setSketchFiles}
                    />
                    <div>
                        <p className='projectForm_form_title'><strong>GALLERIE DE PHOTOS</strong></p>
                    </div>
                    <DNDGallery
                        isCaptionFormAvailable={false}
                        imageFiles={imageFiles} 
                        setImageFiles={setImageFiles} 
                        mainImageIndex={mainImageIndex} 
                        setMainImageIndex={setMainImageIndex} 
                        displayClass={'column'}
                        />
                    <FormImageField
                        htmlFor={'inputImage'}
                        label={'TÉLÉCHARGER UNE IMAGE'}
                        type={'file'}
                        id={'inputImage'}
                        name={'image'}
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                    />
                </div>
                <div className='projectForm_submitButton'>
                    <button type='submit'>ENVOYER</button>
                    {/* <button type='button' onClick={() => setConfirmBoxState(true)}>SORTIR</button> */}
                </div>
                {captionModalDisplay===true &&
                <div className='projectForm_imageCaptionFormModal'>
                    <FormCaptionField
                        htmlFor={'inputSketchCaption'}
                        label={'LÉGENDE'}
                        type={'text'}
                        id={'inputSketchCaption'}
                        ref={inputImageCaptionRef}
                        value={caption}
                        onChangeFunction={handleCaptionChange}
                        onChangeDateFunction={handleDateChange}
                        index={captionIndex}
                        closeModal={closeCaptionModal}
                        captionSubmit={captionSubmit}
                        imageFiles={sketchFiles}
                        setDateCaption={setDateCaption}
                        projectFormMode={projectFormMode}
                        dateCaption={dateCaption}
                    />
                </div>
                }
            </form>
        </div>
    )
}

export default ProjectForm